import { Helmet } from 'react-helmet'
import {useParams} from "react-router-dom";

const DynamicTitle = ({ title = "" }) => {
    const {serviceName}=useParams()
    return (
        <Helmet>
            <title>Ulta Beauty Services | {title}{serviceName? ` Service | ${serviceName}`:""}</title>
        </Helmet>
    )
}
export default DynamicTitle