import getScriptUrl from "../../utils/helper/envConfig"; 
const scriptUrl =  getScriptUrl();

export const snippetForEnv = (url) => {
  return `window.utag_cfg_ovrd = {noview : true};
    (function(a,b,c,d){
    a='${url}';
    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
    a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
    })();`
}

export const tealiumScript = () => {
  return snippetForEnv(scriptUrl.REACT_APP_TEALIUM_SCRIPT_URL);
}
