function snippetForEnv(scriptUrl) {
    if (!scriptUrl) {
        return null;
    }
    return scriptUrl;
}

export default function getScriptSnippet() {
    let scriptUrl;
    if (typeof window !== 'undefined' && window.APP_CONFIG) {
        scriptUrl = window.APP_CONFIG;
        console.log('--> envConfig :: window ')
    } else if (typeof process !== 'undefined' && process.env) {
        scriptUrl = process.env;
        console.log('--> envConfig :: process ')
    }
    return snippetForEnv(scriptUrl);
}