import { Route } from "react-router-dom";
import React from "react";
import DynamicTitle from "../components/Header/DynamicTitle";

export const SubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <>
          <DynamicTitle title={route.title} />
          <route.component {...props} routes={{ ...route }} />
        </>

      )}
    />
  );
};
