import {
  FETCH_APPOINTMENTS_SERVICES_SUCCESS,
  FETCH_APPOINEMENTS_STORE_SELECTED_DATA_SUCCESS,
  IS_GEO_LOCATION_ENABLED,
  FETCH_STYLIST_SELECTION_SERVICES_SUCCESS,
  RESET_ALL_SERVICES_REMOVED,
  FETCH_TIMESLOT__DATA_SUCCESS,
  FETCH_TIMESLOT_DATA,
  FETCH_SELECTED_STYLIST,
  FETCH_FAILED_CONFIRMATION_DATE,
  FETCH_APPOINTMENTDETAILS_DATA,
  FETCH_APPOINTMENTDETAILS_DATA_SUCCESS,
  FETCH_APPOINTMENTDETAILS_DATA_FAILURE,
  CURRENT_BOOKINGSTATE_UPDATE,
  SET_APPOINTMENT_BOOKED_STATUS
} from "./types";

export const fetchCategoryData = (data) => {
  return {
    type: FETCH_APPOINTMENTS_SERVICES_SUCCESS,
    data,
  };
};

export const resetAllRemoved = (data) => {
  return {
    type: RESET_ALL_SERVICES_REMOVED,
    data,
  };
};

export const getServiceData =
  (selectedService, isNullService = false) =>
  async (dispatch) => {
    dispatch(fetchCategoryData(selectedService));
    dispatch(resetAllRemoved(isNullService));
  };
export const setSelectedStoreData = (data) => {
  return {
    type: FETCH_APPOINEMENTS_STORE_SELECTED_DATA_SUCCESS,
    data,
  };
};

export const selectedStoreData = (data) => async (dispatch) => {
  dispatch(setSelectedStoreData(data));
};

export const getGeolocationEnabled = (data) => {
  return {
    type: IS_GEO_LOCATION_ENABLED,
    data,
  };
};

export const isGeolocationEnabled = (data) => async (dispatch) => {
  dispatch(getGeolocationEnabled(data));
};

export const fetchSelectedDate = (data) => {
  return {
    type: FETCH_STYLIST_SELECTION_SERVICES_SUCCESS,
    data,
  };
};

export const getSelectedDate = (selectedDate) => async (dispatch) => {
  if (selectedDate?.isAppintmentcard) {
    dispatch(fetchSelectedDate(null));
    dispatch(
      fetchFailedConfirmationDate({
        dates: selectedDate?.dates || null,
        timeSlot: selectedDate?.timeSlot || null,
        time: selectedDate?.time || null,
      })
    );
  } else if (selectedDate?.isDeleted) {
    dispatch(fetchFailedConfirmationDate(null));
    dispatch(
      fetchSelectedDate({
        dates: selectedDate?.dates || null,
        timeSlot: selectedDate?.timeSlot || null,
        time: selectedDate?.time || null,
      })
    );
  } else {
    dispatch(fetchSelectedDate({
      dates: selectedDate?.dates || null,
      timeSlot: selectedDate?.timeSlot || null,
      time: selectedDate?.time || null,
    }));
  }
};

export const fetchTimeSlotData = (data) => {
  return {
    type: FETCH_TIMESLOT_DATA,
    data,
  };
};

export const fetchTimeSlotsDataSucess = (data) => {
  return {
    type: FETCH_TIMESLOT__DATA_SUCCESS,
    data,
  };
};

export const fetchAppointmentDetailsData = (data) => {
  return {
    type: FETCH_APPOINTMENTDETAILS_DATA,
  };
};
export const fetchAppointmentDetailsDataSuccess = (data) => {
  return {
    type: FETCH_APPOINTMENTDETAILS_DATA_SUCCESS,
    data,
  };
};
export const fetchAppointmentDetailsDataFailure = (data) => {
  return {
    type: FETCH_APPOINTMENTDETAILS_DATA_FAILURE,
  };
};

export const getTimeSlotData = (data) => async (dispatch) => {
  dispatch(fetchTimeSlotsDataSucess(data));
};

export const fetchSelectedStylist = (data) => {
  return {
    type: FETCH_SELECTED_STYLIST,
    data,
  };
};
export const fetchUserType = (data) => {
  return {
    type: FETCH_SELECTED_STYLIST,
    data,
  };
};

export const getSelectedStylist = (data) => async (dispatch) => {
  dispatch(fetchSelectedStylist(data));
};

export const fetchFailedConfirmationDate = (data) => {
  return {
    type: FETCH_FAILED_CONFIRMATION_DATE,
    data,
  };
};

export const getFailedConfirmationDate = (data) => async (dispatch) => {
  dispatch(fetchFailedConfirmationDate(data));
};

export const currentBookingStateUpdate = (bookingstate) => {
  return {
    type: CURRENT_BOOKINGSTATE_UPDATE,
    bookingstate
  }
}

export const resetAppointmentData = (data) => async (dispatch) => {
  dispatch({type: SET_APPOINTMENT_BOOKED_STATUS, data})
};
