import getScriptUrl from "../../utils/helper/envConfig"; 
const scriptUrl =  getScriptUrl();

export const quantumMetricScript = () => {
  const url = scriptUrl.REACT_APP_QUANTUM_METRICS_SCRIPT_URL;
  return `
  (function() {
    var qtm = document.createElement('script'); qtm.type = 'text/javascript'; qtm.async = 1;
    qtm.src = '${url}';
    var d = document.getElementsByTagName('script')[0];
    !window.QuantumMetricAPI && d.parentNode.insertBefore(qtm, d);
  })();
  `;
}
