import morning from "../assets/image/Morning.svg";
import afternoon from "../assets/image/Afternoon.svg";
import evening from "../assets/image/Evening.svg";

export const textFiltering = {
  StoreSearch: {
    heading: (name) => `Hi ${name}! Where would you like to book?`,
    serviceText: "Services",
    searchText: "e.g. Chicago IL or 60603",
    searchAccessibilityLabel: "Search for stores",
    clearSearchAccessibilityLabel: "Clear search text",
    currentLocationAccessibilityLabel: "Current location",
    noMoreStoreMessage:
      "There are no more stores to see within this zipcode! Please choose a new location to search.",
    noAvailablestore:
      "There are currently no stores available in your area. Please try to search another location.",
    suggestion:
      " Here are your closest stores. Tap to select your preferred store. Don’t worry - you can change your mind later!",
    showMore: "SHOW MORE",
    sorryMessage: "We are sorry.",
    stylistSelectionPage:
      "Great! These expert stylists are available:",
    searchCategoryPage: "Ulta Beauty Salon services",
    searchCategoryPageContent:
      "Select a service to see available dates and times. You can add more services later.",
    showAllStylist: "Show all stylists",
    exploreStaticText:"Choose a stylist of a team from below and select \"Pick a new date\" to explore appointment times on another date."
  },
  Login: {
    heading: "Welcome to The Salon at Ulta Beauty",
  },
  stylistCategoryServices: {
    stylistCategoryPage: (name) => `${name}'s services`,
    changeStylist:"Change stylist",
    chooseStylistDialog:"Changing your stylist will remove any of their services you have selected. Are you sure you want to continue?",
    chooseStylistDialogYes:"Yes, continue",
    addStylist:"Add another stylist",
    wantService:"Want a service this stylist doesn’t offer?",
    backToCategoryPage:"Back to categories",
    viewModalContent: "View and edit your services here.",
    backToServicesPage: "Back to services",
  },
  appointmentCard: {
    removelocation:
      "Are you sure you want to edit this salon location? Editing location will result in starting the booking process over.",
    heading: "Your appointment card",
    checkServiceWithStylist: "All stylists must have a service to select a date and time",
    editSelectedlocation: "YES, CONTINUE",
    note: "Note: These services are not booked yet.",
    noLocation: "No location selected",
    salondetails: "Salon details",
    servicesarenotbooked: "Note: These services are not booked yet.",
    noStoreSelectd: "No store selected",
    selectService: "SELECT SERVICES",
    selectStylist: "SELECT STYLIST",
    selectdateTime: "Select date & Time",
    dateandtime: "DATE & TIME",
    edit: "EDIT",
    yourServices: "Your services",
    noServicesSelected: " No services selected",
    noServicesSelectedAppointmentCard: "You have not added any services",
    addAnotherService: "Add another service",
    addAnotherStylist:"Add a service with a new stylist",
    appointmentEdit: "Edit",
    services: (service) =>
      service.length === 1
        ? `${service.length} SERVICE`
        : `${service.length} SERVICES`,
    chooseStylist: "CHOOSE STYLIST",
    selectReview: "REVIEW",
    textLink: "Cancel",
    editSelectedSalonlocation: "Yes, continue",
    editSelectedSalonlocationHeader: "Edit Salon Location",
    editSelectedSalonlocationBody: "Editing location will result in starting the booking process over.",
  },
  common: {},
  sharelocation: {
    closelocation: "Find closest location",
    salonnearyou:
      "Sharing your location allows us to show you salon services near you.",
    buttontext: "share my location",
  },
  bookappointment: {
    message:
      "The selected stylist, service, or store is not available. Please continue to book a new appointment.",
    btntext: "Book a new appointment",
  },
  notvalidstores: {
    novalidstores: "Please enter a valid street address, city, or zipcode.",
  },
  storesError: {
    nostoresError: "Please Enter 5 or More Characters",
  },
  selectedLocation: {
    heading: "Great choice! Meet your preferred salon.",
    callUs: "Call us",
    edit: "EDIT",
    saloneService: "Salon services",
  },
  edit: {
    removeServiceName: (serviceName) =>
      `Are you sure you want to remove ${serviceName} from your apppointment card?`,
    removeService: "Are you sure you want to remove this service?",
    removeSingleService:
      `This is the only service in your ` +
      `upcoming appointment,removing this ` +
      `service will lead to cancellation of your ` +
      `appointment.Are you sure you want to ` +
      `remove this service?`,
    removeLastServiceForStylist:"Warning! You are about to remove this stylist and their associated services. Are you sure you want to continue?",
    removeAppointment: "Are you sure you want to cancel this appointment?",
    bookingSelection: "Warning! You are about to start the booking process over and your current selections will be removed. Are you sure you want to continue?",
    yes: "YES",
    no: "No",
    yesAppt: "YES, CONTINUE",
    changeDate: "Change Date & Time",
    cancelAppointment: "Cancel Appointment",
    callToRebook: "In order to reschedule or rebook this appointment, please call the salon.",
    bookAgain: "Book again",
    backToDashboard: "BACK TO DASHBOARD",
    done: "Done",
    remove: "REMOVE",
    edit: "Edit",
    editSelectedlocation: "YES, CONTINUE",
  },
  services: {
    heading: (heading) => heading,
    price: (price) => `${price}`,
    startingPrice: (price) => `Starting at ${price}`,
    newdate: "PICK A NEW DATE",
    durationMin: (duration) => `${duration && Object.keys(duration).length > 0 ? "Up to " : ''}${getDurationInMinutes(duration)}`,
    newstylist: "PICK A NEW STYLIST",
  },
  AppointmentConfirm: {
    heading: "You’re all set!",
    subHeading: "Looking forward to seeing you.",
    headingInfoText: "We’ve just sent a confirmation text to your phone.",
    dateAndTimeSubText: "WE WILL SEE YOU",
    appointmentDetails: "Appointment details",
    salon: "SALON",
    callUs: "Call Us",
    dateTime: "DATE & TIME",
    yourService: "Your services",
    estimatedTotal: "Estimated total",
    estimatedTotalTime: "Estimated total time",
    manageBooking: "MANAGE BOOKING",
    earnPoints: "Earn points on all beauty services!",
    join: "Earn points on all beauty services! Join the Ultamate Rewards program to earn points on beauty services and products. The best part? You can redeem your points for discounts on future beauty services or purchases.",
    learnMore: "Learn more & join",
    info: "Important information",
    beforeAppointment: "Before your appointment",
    beforeAppointmentContent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.",
    cancelPolicy: "Our cancellation policy",
    cancelPolicyContent:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie.",
    durationMin: (duration) =>
      `${Object.keys(duration).length > 0 ? "" : null}
      ${getDurationInMinutes(duration)}`,
    navBlank: "_blank",
    ultaWeb: "https://www.ulta.com/",
  },
  calendar: {
    morning: { text: "Morning", time: "9AM - 12PM", imgName: morning },
    afternoon: { text: "Afternoon", time: "12PM - 4PM", imgName: afternoon },
    evening: { text: "Evening", time: "4PM - 9PM", imgName: evening },
    selectAll: "Select multiple times to see more stylists.",
    selectAllHeading: " Great! What time works best?",
  },
  pastSalons: {
    heading: (heading) => heading,
    loadMore: "SHOW MORE",
    bookAppt: "BOOK APPOINTMENT",
    bookanAppt: "BOOK AN APPOINTMENT",
  },
  appointmentReview: {
    appointmentHoldText:
      "We are holding your appointment. Confirm and book once you review.",
    appointmentAlmostDoneText: "Almost done!",
    ppointmentLetsReviewText: "Let’s review the details.",
    appointmentNote:
      "Note: These services are not booked until you select “Confirm & Book”.",
    apointmentHeader1: "Basic details",
    apointmentHeader2: "Your services",
    apointmentHeader3: "Estimated total",
    basicDetails: "Basic details",
    salonHeader: "SALON",
    dateAndTime: "DATE & TIME",
    yourService: "Your services",
    estimatedTotal: "Estimated total",
    estimatedTotalTime: "Estimated total time",
    source: "web",
    durationMin: (duration) =>
      `${Object.keys(duration).length > 0 ? "" : null}
    ${getDurationInMinutes(duration)}`,
  },
  stepper: {
    step1: (currentSteps, totla) =>
      `Step ${currentSteps} of ${totla}: Salon selection`,
    step2: (currentSteps, totla) =>
      `Step ${currentSteps} of ${totla}: Service selection`,
    step3: (currentSteps, totla) =>
      `Step ${currentSteps} of ${totla}: Date & Time selection`,
    step4: (currentSteps, totla) =>
      `Step ${currentSteps} of ${totla}: Stylist selection`,
    step5: (currentSteps, totla) => `Step ${currentSteps} of ${totla}: Review`,
  },
  dashboard: {
    heading: (heading) => heading,
    topHeading: (name) => `Hi, ${name}! Welcome.`,
    topSubHeading: "Thanks for staying connected with us.",
    edit: "EDIT",
    pnoneNo: (pnoneNo) => `${pnoneNo}`,
    name: (name) => `Hi ${name}!`,
    logout: "LOG OUT",
    modalMsg:
      "By exiting to the dashboard you will lose this current appointment. Are you sure you want to continue?",
    continue: "CONTINUE",
    cancel: "Cancel",
    hi: "Hi,",
    firstName: "First name",
    lastName: "Last name",
  },
  upcomingAppointments: {
    heading: "Upcoming Appointments",
    Add_to_Calendar: 'Add to Calendar',
  },
  pastAppointments: {
    heading: "Past Appointments",
  },
  dashboardTabs: {
    tab1: "Upcoming Appointments",
    tab2: "Past Appointments",
    tab3: "Past Salons",
  },
  noupcomingappointmentstitle: {
    heading: "NO APPOINTMENTS",
  },
  noupcomingappointments: {
    heading: "You don’t have any upcoming appointments.",
  },
  nopastsalontitle: {
    heading: "NO PAST SALONS",
  },
  nopastsalons: {
    heading: "You don’t have any past salons.",
  },
  stylist: ["Stylist -"],
  expertStylist: {
    heading: "Great! These expert stylists are available:",
  },

  masterstylist: ["Master Stylist"],
  nopastappointments: {
    heading: "You don’t have any past appointments.",
  },
  pickANewDateRoutePath: "/picknewdate",
  stylistFlowName: "Stylist",
  stylist: {
    changeStylist: "Change Stylist"
  },
  pickNewDate: {
    nonAvailability: "Don't see your preferred availability?",
    contactSalon: "Review your appointment card to modify your services or call your salon."
  },
  addAnotherStylistPage: 'You are sucessfully landed on add another stylist page',
  addAnotherServicePage: 'You are sucessfully landed on add another service page',
  REBOOK_BOOKING_STATE: 'rebooking',
  RESCHEDULE_BOOKING_STATE: 'reschedule',
  ALL_SERVICES_ADDED_MESSAGE: "You've added all the available services in this category. Try a different category or stylist.",
  RETURN_TO_SALON: "Return to salon details",
  TIMER_KEY: 'timer',
};


export const getDurationInMinutes = (duration) => {
  const hrInText = `${
    duration?.hr
    ?
      duration?.hr + 
      (duration?.hr > 1 ? ' Hrs' : ' Hr')
    : ''
  }`;
  const minInText = `${
    duration?.min
      ?
        `${duration?.hr ? ' ' : ''}${duration?.min}` + 
        (duration?.min > 1 ? ' Mins' : ' Min')
      : ''
  }`;
return `${hrInText}${minInText}`
}
