export const FETCH_APPOINTMENTS_SERVICES_SUCCESS =
  "FETCH_APPOINTMENTS_SERVICES_SUCCESS";
export const FETCH_APPOINEMENTS_STORE_SELECTED_DATA_SUCCESS =
  "FETCH_APPOINEMENTS_STORE_SELECTED_DATA_SUCCESS";
export const IS_GEO_LOCATION_ENABLED = "IS_GEO_LOCATION_ENABLED";
export const FETCH_STYLIST_SELECTION_SERVICES_SUCCESS =
  "FETCH_STYLIST_SELECTION_SERVICES_SUCCESS";
export const REMOVE_SELECTED_SERVICE_SUCCESS =
  "REMOVE_SELECTED_SERVICE_SUCCESS";
export const RESET_ALL_SERVICES_REMOVED = "RESET_ALL_SERVICES_REMOVED";
export const FETCH_TIMESLOT_DATA = "FETCH_TIMESLOT_DATA";
export const FETCH_TIMESLOT__DATA_SUCCESS = "FETCH_TIMESLOT__DATA_SUCCESS";
export const FETCH_RESERVEAPPOINTMENTDATA = "FETCH_RESERVEAPPOINTMENTDATA";
export const FETCH_RESERVEAPPOINTMENTDATA_SUCCESS =
  "FETCH_RESERVEAPPOINTMENTDATA_SUCCESS";
export const FETCH_RESERVEAPPOINTMENTDATA_FAILURE =
  "FETCH_RESERVEAPPOINTMENTDATA_FAILURE";
export const FETCH_SELECTED_STYLIST = "FETCH_SELECTED_STYLIST";
export const FETCH_CONFIRMAPPOINTMENT_DATA = "FETCH_CONFIRMAPPOINTMENT_DATA";
export const FETCH_CONFIRMAPPOINTMENT_DATA_SUCCESS =
  "FETCH_CONFIRMAPPOINTMENT_DATA_SUCCESS";
export const FETCH_CONFIRMAPPOINTMENT_DATA_FAILURE =
  "FETCH_CONFIRMAPPOINTMENT_DATA_FAILURE";
export const FETCH_FAILED_CONFIRMATION_DATE = "FETCH_FAILED_CONFIRMATION_DATE";
export const FETCH_APPOINTMENTDETAILS_DATA = "FETCH_APPOINTMENTDETAILS_DATA";
export const FETCH_APPOINTMENTDETAILS_DATA_SUCCESS = "FETCH_APPOINTMENTDETAILS_DATA_SUCCESS";
export const FETCH_APPOINTMENTDETAILS_DATA_FAILURE = "FETCH_APPOINTMENTDETAILS_DATA_FAILURE";
export const CURRENT_BOOKINGSTATE_UPDATE = "CURRENT_BOOKINGSTATE_UPDATE";
export const DELETE_APPTSOFTBOOKING_DATA = "DELETE_APPTSOFTBOOKING_DATA";
export const DELETE_APPTSOFTBOOKING_DATA_SUCCESS = "DELETE_APPTSOFTBOOKING_DATA_SUCCESS";
export const DELETE_APPTSOFTBOOKING_DATA_FAILURE = "DELETE_APPTSOFTBOOKING_DATA_FAILURE";
export const RESET_APPOINTMENT_CARD_ON_FLOW_CHANGE = "RESET_APPOINTMENT_CARD_ON_FLOW_CHANGE";
export const SET_APPOINTMENT_BOOKED_STATUS = "SET_APPOINTMENT_BOOKED_STATUS";
